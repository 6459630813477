exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bbu-js": () => import("./../../../src/pages/bbu.js" /* webpackChunkName: "component---src-pages-bbu-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-epc-hss-js": () => import("./../../../src/pages/epc/hss.js" /* webpackChunkName: "component---src-pages-epc-hss-js" */),
  "component---src-pages-epc-index-js": () => import("./../../../src/pages/epc/index.js" /* webpackChunkName: "component---src-pages-epc-index-js" */),
  "component---src-pages-epc-mme-js": () => import("./../../../src/pages/epc/mme.js" /* webpackChunkName: "component---src-pages-epc-mme-js" */),
  "component---src-pages-epc-pcrf-js": () => import("./../../../src/pages/epc/pcrf.js" /* webpackChunkName: "component---src-pages-epc-pcrf-js" */),
  "component---src-pages-epc-sgwc-js": () => import("./../../../src/pages/epc/sgwc.js" /* webpackChunkName: "component---src-pages-epc-sgwc-js" */),
  "component---src-pages-epc-sgwu-js": () => import("./../../../src/pages/epc/sgwu.js" /* webpackChunkName: "component---src-pages-epc-sgwu-js" */),
  "component---src-pages-five-gc-af-js": () => import("./../../../src/pages/fiveGC/af.js" /* webpackChunkName: "component---src-pages-five-gc-af-js" */),
  "component---src-pages-five-gc-amf-js": () => import("./../../../src/pages/fiveGC/amf.js" /* webpackChunkName: "component---src-pages-five-gc-amf-js" */),
  "component---src-pages-five-gc-ausf-js": () => import("./../../../src/pages/fiveGC/ausf.js" /* webpackChunkName: "component---src-pages-five-gc-ausf-js" */),
  "component---src-pages-five-gc-bsf-js": () => import("./../../../src/pages/fiveGC/bsf.js" /* webpackChunkName: "component---src-pages-five-gc-bsf-js" */),
  "component---src-pages-five-gc-index-js": () => import("./../../../src/pages/fiveGC/index.js" /* webpackChunkName: "component---src-pages-five-gc-index-js" */),
  "component---src-pages-five-gc-nef-js": () => import("./../../../src/pages/fiveGC/nef.js" /* webpackChunkName: "component---src-pages-five-gc-nef-js" */),
  "component---src-pages-five-gc-nrf-js": () => import("./../../../src/pages/fiveGC/nrf.js" /* webpackChunkName: "component---src-pages-five-gc-nrf-js" */),
  "component---src-pages-five-gc-nssf-js": () => import("./../../../src/pages/fiveGC/nssf.js" /* webpackChunkName: "component---src-pages-five-gc-nssf-js" */),
  "component---src-pages-five-gc-pcf-js": () => import("./../../../src/pages/fiveGC/pcf.js" /* webpackChunkName: "component---src-pages-five-gc-pcf-js" */),
  "component---src-pages-five-gc-ran-js": () => import("./../../../src/pages/fiveGC/ran.js" /* webpackChunkName: "component---src-pages-five-gc-ran-js" */),
  "component---src-pages-five-gc-scp-js": () => import("./../../../src/pages/fiveGC/scp.js" /* webpackChunkName: "component---src-pages-five-gc-scp-js" */),
  "component---src-pages-five-gc-smf-js": () => import("./../../../src/pages/fiveGC/smf.js" /* webpackChunkName: "component---src-pages-five-gc-smf-js" */),
  "component---src-pages-five-gc-udm-js": () => import("./../../../src/pages/fiveGC/udm.js" /* webpackChunkName: "component---src-pages-five-gc-udm-js" */),
  "component---src-pages-five-gc-udr-js": () => import("./../../../src/pages/fiveGC/udr.js" /* webpackChunkName: "component---src-pages-five-gc-udr-js" */),
  "component---src-pages-five-gc-upf-js": () => import("./../../../src/pages/fiveGC/upf.js" /* webpackChunkName: "component---src-pages-five-gc-upf-js" */),
  "component---src-pages-ims-index-js": () => import("./../../../src/pages/ims/index.js" /* webpackChunkName: "component---src-pages-ims-index-js" */),
  "component---src-pages-ims-vilte-js": () => import("./../../../src/pages/ims/vilte.js" /* webpackChunkName: "component---src-pages-ims-vilte-js" */),
  "component---src-pages-ims-vinr-js": () => import("./../../../src/pages/ims/vinr.js" /* webpackChunkName: "component---src-pages-ims-vinr-js" */),
  "component---src-pages-ims-volte-js": () => import("./../../../src/pages/ims/volte.js" /* webpackChunkName: "component---src-pages-ims-volte-js" */),
  "component---src-pages-ims-vonr-js": () => import("./../../../src/pages/ims/vonr.js" /* webpackChunkName: "component---src-pages-ims-vonr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-monitoring-siem-js": () => import("./../../../src/pages/monitoring/siem.js" /* webpackChunkName: "component---src-pages-monitoring-siem-js" */),
  "component---src-pages-monitoring-tools-js": () => import("./../../../src/pages/monitoring/tools.js" /* webpackChunkName: "component---src-pages-monitoring-tools-js" */)
}

